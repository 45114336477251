import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Modal,
  ModalContent,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Head from "next/head";
import { useEffect, useMemo, useRef, useState } from "react";
import Layout from "../components/Layout.js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import UnsupportedResolution from "../components/UnsupportedResolution";
dayjs.extend(duration);
import { motion, useAnimation } from "framer-motion";
import Mobile from "./mobile";

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionText = motion(Text);

const motionDuration = 1;

const transitionSettings = {
  duration: motionDuration,
  delay: 2,
  type: "spring",
  stiffness: 50,
};

export default function Home() {
  const ref3 = useRef(null);
  const animationControls = useAnimation();
  async function sequence() {
    await animationControls.start({
      scale: 2.5,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    });

    await animationControls.start({
      scale: 1.3,
      translateY: "-42.5%",
      translateX: "-72.5%",
      transition: {
        duration: 1,
        delay: 1,
        type: "spring",
        stiffness: 50,
      },
    });
  }

  useEffect(() => {
    sequence();
  }, []);

  const ITSocialIcon = (props) => {
    return (
      <Link
        isExternal
        href={props.url}
        bgImage={`/${props.imageName}`}
        bgRepeat="no-repeat"
        bgSize="contain"
        height="20"
        width="20"
      />
    );
  };

  if (window.innerWidth < 360) return <UnsupportedResolution />;

  if (window.innerWidth < 1280) return <Mobile />;

  return (
    <Layout>
      <Head>
        <title>Impact Theory Scary Modz</title>
        <meta name="description" content="Impact Theory Scary Modz" />
        <link rel="icon" href="/favicon.png" />
        <meta property="og:title" content="Impact Theory Scary Modz" />
        <meta property="og:url" content="https://scarymodz.io" />
        {/* <meta property="og:image" content="https://impacttheory.com/wp-content/uploads/2018/11/impact-theory-logo.png" /> */}
      </Head>

      {/* Landing Section */}
      <Box
        priority="true"
        fontFamily="Typrighter-B"
        bgImage="/MainBG@2x.jpg"
        bgSize="cover"
        bgRepeat="no-repeat"
        backgroundPosition="75% -0%"
        minHeight="1615px"
        width="100%"
      >
        <Container maxW="8xl">
          <Flex>
            <Box
              width="50%"
              position="relative"
              transform="auto"
              scale="140%"
              marginLeft="10%"
              marginTop="5%"
            >
              <MotionImage
                priority="true"
                src="/Logo@2x.png"
                objectFit="contain"
                transform="auto"
                initial={{ x: "72.5%", y: "70%", scale: 0.5 }}
                animate={animationControls}
                //scale="220%"
              />
              <MotionImage
                priority="true"
                src="/Max-box.png"
                objectFit="contain"
                transform="auto"
                initial={{ x: "-200%", y: "-24%", scale: 1.2 }}
                animate={{
                  translateX: "185%",
                }}
                transition={transitionSettings}
                zIndex="2"
              />
            </Box>
            <MotionBox
              initial={{ x: "200%", y: "0", scale: 1 }}
              animate={{
                translateX: "-200%",
              }}
              transition={{
                duration: motionDuration,
                delay: 2,
                type: "spring",
                stiffness: 65,
              }}
              width="100%"
              alignSelf="center"
            >
              <Box pl="30%">
                <Text
                  fontFamily="Another-America"
                  transform="auto"
                  fontSize="5rem"
                  letterSpacing="6.3px"
                  zIndex="overlay"
                  color="#E2FE01"
                  py="60px"
                >
                  FACE YOUR FEARS
                </Text>
                <Text width="480px" fontSize="23px" letterSpacing="1px">
                  Welcome the newest monsters to the Modz family.
                </Text>
                <Heading
                  fontSize="38px"
                  color="black"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  bgPos="left"
                  minHeight="135px"
                  paddingLeft="10px"
                  paddingTop="65px"
                  width="100%"
                  bgImage="/launchingearlybox.svg"
                  fontFamily="Typrighter-B"
                >
                  Launching early 2023.
                </Heading>
              </Box>
            </MotionBox>
          </Flex>
        </Container>
      </Box>

      {/* About Section  */}
      <MotionBox
        fontFamily="Typrighter-B"
        bgImage="/About-BG@2x.png"
        bgSize="cover"
        minHeight={{ base: "1715px", "1xl": "1900" }}
        width="100%"
        transform="auto"
        zIndex="overlay"
        marginTop="-900px"
        paddingTop="45%"
        position="relative"
        initial={{ y: "50%" }}
        animate={{
          translateY: "-50%",
        }}
        transition={transitionSettings}
      >
        <Container
          maxW="8xl"
          transform="auto"
          translateY={{ base: "-15%", "1xl": "-25%", xl: "-25%" }}
        >
          <Flex>
            <Box width="50%" alignSelf="center" pl="5%">
              <Box>
                <Image
                  src="/Rectangle-392-20.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-40%",
                    xxl: "-40%",
                    "1xl": "-50%",
                    "2xl": "-50%",
                  }}
                  height={{ base: "20" }}
                  width="20"
                />
                <Image
                  src="/Rectangle-392-18.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-40%",
                    xxl: "-40%",
                    "1xl": "-50%",
                    "2xl": "-50%",
                  }}
                  left={{ base: "15%" }}
                  height="8"
                  width="8"
                />
                <Image
                  src="/Rectangle-392-17.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-50%",
                    xxl: "-50%",
                    "1xl": "-60%",
                    "2xl": "-60%",
                  }}
                  left={{ base: "45%" }}
                  height="6"
                  width="6"
                />
                <Image
                  src="/Rectangle-392-17.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-45%",
                    xxl: "-45%",
                    "1xl": "-55%",
                    "2xl": "-55%",
                  }}
                  left={{ base: "44%" }}
                  height="3"
                  width="3"
                />
                <Image
                  src="/Rectangle-393-4@.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-45%",
                    xxl: "-45%",
                    "1xl": "-55%",
                    "2xl": "-55%",
                  }}
                  left={{ base: "45%" }}
                  height="32"
                  width="32"
                />
                <Image
                  src="/Rectangle-392-14.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-35%",
                    xl: "-35%",
                    "1xl": "-45%",
                    "2xl": "-45%",
                  }}
                  left={{ base: "40%" }}
                  height="16"
                  width="16"
                />
                <Image
                  src="/Rectangle-392-13.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-45%",
                    xxl: "-45%",
                    "1xl": "-55%",
                    "2xl": "-55%",
                  }}
                  right={{ base: "20%" }}
                  height="16"
                  width="16"
                />
                <MotionImage
                  src="/Rectangle-392-16.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-46%",
                    xxl: "-46%",
                    "1xl": "-56%",
                    "2xl": "-56%",
                  }}
                  right={{ base: "15%" }}
                  height="12"
                  width="12"
                />
                <Image
                  src="/Rectangle-392-16.png"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{
                    base: "-63%",
                    xxl: "-63%",
                    "1xl": "-73%",
                    "2xl": "-73%",
                  }}
                  right={{ base: "-2%" }}
                  height="6"
                  width="6"
                />
                <MotionBox
                  initial={{ x: "-100%", hidden: true }}
                  whileInView={{
                    x: "0%",
                    hidden: false,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: motionDuration,
                    delay: 0.2,

                    ease: "easeOut",
                  }}
                >
                  <Heading
                    color="white"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    bgPos="left"
                    minHeight="250px"
                    paddingLeft="120px"
                    paddingTop="70px"
                    bgImage="/about-paint.svg"
                    fontFamily="Another-America"
                    fontSize="5rem"
                    letterSpacing="6.3px"
                  >
                    ABOUT
                  </Heading>
                </MotionBox>

                <MotionBox
                  fontSize="1.5rem"
                  lineHeight="2.5rem"
                  letterSpacing=".1rem"
                  initial={{ x: "-100%", hidden: true }}
                  whileInView={{
                    x: "0%",
                    hidden: false,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: motionDuration,
                    delay: 0.2,

                    ease: "easeOut",
                  }}
                  width="650px"
                  height="330px"
                >
                  <Text>When the Monster World falls under</Text>
                  <Text>the rule of a horrible creature that threatens</Text>
                  <Text>their very existence, a team of teenage</Text>
                  <Text>monsters travel to our world to kidnap the</Text>
                  <Text>offspring of a legendary human Monster</Text>
                  <Text>Hunter. But when they get home they realize</Text>
                  <Text>the hero they thought they recruited is a</Text>
                  <Text>complete and total scaredy-cat.</Text>
                </MotionBox>
              </Box>
            </Box>
            <Box
              width={{ base: "550px", "2xl": "657px" }}
              height={{ base: "780px", "2xl": "927px" }}
            >
              <MotionImage
                initial={{ x: "100%", hidden: true }}
                whileInView={{
                  x: "0%",
                  hidden: false,
                }}
                viewport={{ root: ref3 }}
                transition={{
                  duration: motionDuration,
                  delay: 0.2,

                  ease: "easeOut",
                }}
                src="/Hecto_main.png"
                objectFit="cover"
                width={{ base: "550px", "2xl": "657px" }}
                height={{ base: "780px", "2xl": "927px" }}
                position="absolute"
                marginTop={{ base: "0%" }}
                marginLeft={{ xlPhone: "0%" }}
              />
            </Box>
          </Flex>
        </Container>
      </MotionBox>

      {/* Character Section  */}
      <Box
        fontFamily="Typrighter-B"
        bgImage="/Character-Line-upBG@2x.png"
        bgSize="cover"
        minHeight="2943px"
        bgRepeat="no-repeat"
        minW="100%"
        transform="auto"
        marginTop={{ base: "-400px", "1xl": "-450px" }}
        paddingTop="25%"
      >
        <Container
          maxW="8xl"
          marginTop="-200px"
          position="relative"
          height="1060px"
          width="1328px"
        >
          <MotionImage
            initial={{ y: "-500px", opacity: 0 }}
            whileInView={{
              y: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 1,
              delay: 0.5,
            }}
            src="/Blaze.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            top={{
              base: "0%",
              xxl: "0%",
              "1xl": "0%",
              "2xl": "0%",
            }}
            right={{ base: "0%" }}
            height="1060px"
            width="1328px"
          />
          <MotionImage
            initial={{ x: "-100%", opacity: 0 }}
            whileInView={{
              x: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Roxy.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "3%",
              xxl: "3%",
              "1xl": "3%",
              "2xl": "3%",
            }}
            left={{ base: "0%" }}
            height="584px"
            width="531px"
          />
          <MotionImage
            initial={{ y: "100%", opacity: 0 }}
            whileInView={{
              y: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0,
            }}
            src="/Cloak.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "-3%",
              xxl: "-3%",
              "1xl": "-3%",
              "2xl": "-3%",
            }}
            right={{ base: "-5%" }}
            height="900px"
            width="774px"
          />
          <MotionImage
            initial={{ x: "100%", opacity: 0 }}
            whileInView={{
              x: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Hecto.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "8%",
              xxl: "8%",
              "1xl": "8%",
              "2xl": "8%",
            }}
            right={{ base: "2%" }}
            height="448px"
            width="299px"
          />

          <MotionImage
            initial={{ x: "100%", opacity: 0 }}
            whileInView={{
              x: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Max.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "-15%",
              xxl: "-15%",
              "1xl": "-15%",
              "2xl": "-15%",
            }}
            right={{ base: "50%" }}
            marginRight="-20%"
            height="854px"
            width="706px"
          />
        </Container>
      </Box>

      {/* Meet the Modz Section  */}
      <Box
        fontFamily="Typrighter-B"
        minHeight={{md: '3800px', xxl: '3800px', xl: '4100px', '2xl':"4000px"}}
        minW="100%"
        transform="auto"
        marginTop={{ xxl: "-1400px", xl: "-1500px", "2xl": "-1400px" }}
        paddingTop="25%"
        position="relative"
        overflow="hidden"
      >
        <MotionBox
          initial={{
            backgroundPosition: "-200px -100%",
          }}
          whileInView={{
            backgroundPosition: "-200px -15%",
          }}
          viewport={{ root: ref3 }}
          transition={{
            duration: 0.6,
            delay: 0.2,
          }}
          bgImage="/Green-Bg.svg"
          bgSize="cover"
          minHeight="5747.76px"
          bgRepeat="no-repeat"
          minW="2800px"
          transform="auto"
          marginTop={{ base: "-90%", xl: "-60%" }}
          position="absolute"
        />
        <Container
          maxW={{ base: "lg", lg: "8xl" }}
          zIndex="overlay"
          transform="auto"
          marginTop={{
            base: "20%",
            xxl: "10%",
            xl: "25%",
            "1xl": "15%",
            "2xl": "5%",
          }}
        >
          <Flex>
            <Stack m="auto" minW={{ base: "2xl", xxl: "5xl", "2xl": "8xl" }}>
              <Box
                position="relative"
                w={["250px", "300px", "500px"]}
                h={["150px", "200px", "300px"]}
                margin="auto"
              >
                <Box
                  bgImage="/Meet-The-Modz-Paint.svg"
                  bgSize="cover"
                  bgRepeat="no-repeat"
                  minW="600px"
                  height="100%"
                  position="absolute"
                  left="-7%"
                  top="-18.5%"
                />
                <Box
                  bgImage="/Meet-The-Modz.svg"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  minW="100%"
                  height="100%"
                  position="absolute"
                  left="0"
                />
              </Box>
              <HStack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Trace-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="350px"
                    bgPosition="center"
                    position="absolute"
                    top="-40px"
                    right="-2%"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Trace-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    transform="auto"
                    scale="120%"
                    bgPosition="center"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>

                <MotionText
                  minW="50%"
                  height="300px"
                  fontSize="23px"
                  letterSpacing="1.5px"
                  initial={{ x: "100%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 1,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Son of the most famous Monster Hunter. Presumably bound to
                  follow in his father’s footsteps except for one thing—he’s a
                  complete and total scaredy-cat. Our team spirits him from the
                  human world into theirs because according to an ancient curse,
                  a human monster hunter is the only one who can kill the big
                  bad.
                </MotionText>
              </HStack>
              <HStack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <MotionText
                  minW="50%"
                  height="300px"
                  fontSize="23px"
                  letterSpacing="1.5px"
                  initial={{ x: "-100%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Our cloaked girl is a WITCH. This young emo-girl, breaks from
                  the tradition of most witches, she eschews things like eye of
                  newt, hair of frogs, and lame potions. Very sensitive, and
                  over-tuned into emotion, she’s shy in the real world, and
                  hides behind a digital image both online and IRL. Rather than
                  spells she can manifest digital powers
                </MotionText>

                <Box position="relative" minW="50%" height="450px" transform='auto' scale='150%'>
                  <MotionBox
                    bgImage="/Cloak-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="360px"
                    bgPosition="center"
                    position="absolute"
                    top="6px"
                    right="-2%"
                    initial={{ x: "50%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Cloak-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="10%"
                    height="450px"
                    transform="auto"
                    scale="140%"
                    bgPosition="center"
                    initial={{ x: "50%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
              </HStack>
              <HStack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Hecto-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="340px"
                    bgPosition="center"
                    position="absolute"
                    top="-36px"
                    right="2%"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Hecto-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    bgPosition="center"
                    transform="auto"
                    scale="120%"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
                <MotionText
                  minW="50%"
                  height="300px"
                  fontSize="23px"
                  letterSpacing="1.5px"
                  initial={{ x: "100%", opacity: 0 }}
                  paddingTop='5%'
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Hekto IS a ghost, but has a problem with oozing excess
                  ectoplasm. It’s a teen thing. In spite of this he’s a pretty
                  cool and confident guy—mostly because he can’t be hurt as he’s
                  vapor.
                </MotionText>
              </HStack>
              <HStack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <MotionText
                  minW="50%"
                  height="300px"
                  fontSize="23px"
                  letterSpacing="1.5px"
                  initial={{ x: "-100%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Abandoned at the monster orphanage, he grew up without a sense
                  of control over his power to grow big or not. It happens at
                  random which can be a huge problem. If the team needs muscle
                  and he’s small, they’re screwed. If the team is in a boat
                  crossing a river and he turns big, they sink. Etc. His
                  personal goal is to learn how to control his ability.
                </MotionText>
                <Box position="relative" minW="50%" height="650px">
                  <MotionBox
                    bgImage="/Blaze-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="540px"
                    bgPosition="center"
                    position="absolute"
                    top="16px"
                    right="4%"
                    initial={{ x: "100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Blaze-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="650px"
                    bgPosition="center"
                    transform="auto"
                    scale="130%"
                    initial={{ x: "100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
              </HStack>
              <HStack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px"
                    marginTop='10%'>
                  <MotionBox
                    bgImage="/Roxy-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="350px"
                    bgPosition="center"
                    position="absolute"
                    top="-16px"
                    right="4%"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Roxy-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    bgPosition="center"
                    transform="auto"
                    scale="120%"
                    initial={{ x: "-100%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
                <MotionText
                  minW="50%"
                  height="300px"
                  fontSize="23px"
                  letterSpacing="1.5px"
                  initial={{ x: "100%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Grew up loving Rock & Roll but as a gargoyle, she is supposed
                  to play only choir music. Her rebellious trait has made her an
                  outsider in her own family. Questions authority. Powers
                  include, flight, the ability to turn her body partially or
                  fully into stone (which allows regeneration from shattering,
                  camouflage, resistance to heat) and the power to ROCK (she can
                  amplify sound using her body). Traditionally, Gargoyles train
                  to serve a role in the human world, as watchful guardians. In
                  their guardian role, they monitor both human activity and any
                  illegal entry into the human world by monsters.
                </MotionText>
              </HStack>
            </Stack>
          </Flex>
        </Container>
      </Box>

      <Box
        width="100%"
        position="absolute"
        bottom="0px"
        height={{ base: "200px", lg: "200px" }}
        maxWidth="1920px"
        margin="auto"
      >
        <Stack alignItems="center">
          <Heading fontFamily="Typrighter-B">Stay Connected!</Heading>
          <Stack direction="row" spacing={16} align="center" pt="40px">
            <ITSocialIcon
              url="https://discord.com/invite/impacttheory"
              imageName="Discord-footer.svg"
            />
          </Stack>
        </Stack>
      </Box>
    </Layout>
  );
}
